// eslint-disable-next-line import/no-cycle
// import Auth from './Auth'

const API = {
  async get(settings) {
    const newSettings = {
      ...{ token: true, route: '', url: '', data: {}, response: null },
      ...settings,
    }

    const fetchRequest = fetch(
      newSettings.url ? newSettings.url : `${this.URL}${newSettings.route}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          ...{
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      }
    )

    if (newSettings.response === 'return') {
      return fetchRequest
    }

    const response = await fetchRequest

    const json = await response.json()

    return { data: json, status: response.status, errors: json.errors }
  },

  async post(settings) {
    const newSettings = {
      ...{ token: true, route: '', url: '', data: {}, response: null },
      ...settings,
    }

    const fetchRequest = fetch(
      newSettings.url ? newSettings.url : `${this.URL}${newSettings.route}`,
      {
        method: 'POST',
        headers: {
          ...{
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          ...this.authorisationHeader(newSettings),
        },
        body: JSON.stringify(newSettings.data),
      }
    )

    if (newSettings.response === 'return') {
      return fetchRequest
    }

    const response = await fetchRequest
    const json = await response.json()

    return { data: json, status: response.status, errors: json.errors }
  },

  async custom(settings) {
    const newSettings = { ...{ token: true, route: '', data: {} }, ...settings }

    const response = await fetch(newSettings.route, {
      method: 'GET',
      header: this.authorisationHeader(newSettings),
    })

    if (response.status >= 400 && response.status <= 600) {
      return { data: null, error: response.status }
    }

    try {
      const json = await response.json()
      return { data: json, error: null }
    } catch (error) {
      return { data: null, error }
    }
  },
  URL: process.env.REACT_APP_API_URI,

  authorisationHeader(settings) {
    let headers = {}

    if (settings.token) {
      //   const jwt = Auth.getCookieJWT()
      //   if(jwt) {
      //     headers = { Authorization: `Bearer ${Auth.getCookieJWT()}` }
      //   }
    }

    return headers
  },
}

export default API
