import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'
import RankingTable from 'components/flex/Ranking/RankingTable'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface RankingProps {}

const StyledRanking = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.primary};
`

const RankingNeumorph = styled(motion.div)`
  border-radius: 30px;
  background: #2b3e63;
  box-shadow: 20px 20px 60px #253554, -20px -20px 60px #314772;
  padding: 15px;

  @media screen and (max-width: 768px) {
    width: 95%;
  }

  @media screen and (max-width: 575px) {
    border-radius: 15px;
  }
`

const RankingBlock = styled(motion.div)`
  color: ${({ theme }) => theme.color.light};
  padding: 30px;
  border-radius: 30px;
  width: 1200px;

  @media screen and (max-width: 1199px) {
    width: 900px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    padding: 0;
    border-radius: 15px;
  }
`

const ImageWrapper = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const RankingTitle = styled.div`
  font-size: 40px;
  line-height: 40px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.secondary};

  & b,
  strong {
    color: ${({ theme }) => theme.color.secondary};
  }
`

const Separator = styled.div`
  height: 1px;
  background-color: #dadada;
  opacity: 0.3;
  width: 100%;
  margin: 20px 0 30px 0;
`

const RankingContent = styled.div``

const Ranking: React.FC<RankingProps> = () => {
  const { file } = useStaticQuery<GatsbyTypes.udglogoQuery>(graphql`
    query udglogo {
      file(name: { eq: "ubodg-logo" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 1000, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <StyledRanking className="d-flex justify-content-center align-items-center">
      <ImageWrapper
        animate={{
          scale: [0, 1, 1],
          opacity: [1, 1, 1, 1, 0],
          y: [0, 0, 10, 10, 400],
          transition: {
            duration: 3,
          },
        }}
      >
        <Plaatjie image={{ localFile: file }} alt="" />
      </ImageWrapper>
      <RankingNeumorph
        animate={{
          boxShadow: [
            '0px 0px 0px #253554, -0px -0px 0px #314772',
            '20px 20px 60px #253554, -20px -20px 60px #314772',
          ],
          transition: { delay: 4 },
        }}
      >
        <RankingBlock
          animate={{
            opacity: [0, 1],
            transition: {
              delay: 4,
            },
          }}
        >
          <RankingTitle>
            <b>UBO</b>
            <br /> DATE
            <br /> GAMES
          </RankingTitle>
          <Separator />
          <RankingContent className="mt-4">
            <RankingTable />
          </RankingContent>
        </RankingBlock>
      </RankingNeumorph>
    </StyledRanking>
  )
}

export default Ranking
