import React from 'react'

// Third Party
import styled from 'styled-components'

interface LoadingProps {}

const StyledLoading = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
`

const Loading: React.FC<LoadingProps> = () => (
  <StyledLoading>Loading</StyledLoading>
)

export default Loading
