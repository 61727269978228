/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby' // Components
import Loading from 'components/flex/Ranking/Loading'

// Services
import API from 'services/API'

// Third Party
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

interface RankingTableProps {}

const StyledRankingTable = styled.div`
  overflow-x: hidden;
`

const Ranks = styled.div`
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 15px 0;

  overflow-y: scroll;
  font-family: ${({ theme }) => theme.font.family.code};
`

const InnerRanks = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 500px;

  @media screen and (max-width: 1199px) {
    height: 50vh;
  }

  @media screen and (max-width: 991px) {
    height: 50vh;
  }
`

const RoundButton = styled.div<{ active: boolean }>`
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: 5px;
  padding: 5px 10px;
  height: 40px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.5;
        `}

  &:hover {
    background-color: ${({ theme }) => theme.color.light};
    color: ${({ theme }) => theme.color.secondary};
  }
`

const IndexChanged = styled.div<{ changed: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 10px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.grey};
  color: #000;
  border-radius: 5px;
  height: 20px;
  padding: 4px;
  z-index: 20;

  ${({ changed }) =>
    changed > 0 &&
    css`
      background-color: #47ff63;
    `}

  ${({ changed }) =>
    changed < 0 &&
    css`
      background-color: tomato;
    `}
`

const AddedPoints = styled.div<{ changed: number }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: rgba(255, 255, 255, 0.4);

  ${({ changed }) =>
    changed > 0 &&
    css`
      color: #47ff63;
    `}
`

const Participant = styled.div<{ number: number }>`
  padding: 2px 40px;
  position: relative;

  ${({ number }) =>
    number === 0 &&
    css`
      font-size: 30px;
      color: #d4af37;

      ${AddedPoints} {
        padding-top: 5px;
      }

      @media screen and (max-width: 575px) {
        font-size: 18px;
      }
    `}

  ${({ number }) =>
    number === 1 &&
    css`
      font-size: 25px;
      color: #a8a9ad;

      ${AddedPoints} {
        padding-top: 5px;
      }

      @media screen and (max-width: 575px) {
        font-size: 18px;
      }
    `}

      ${({ number }) =>
    number === 2 &&
    css`
      font-size: 20px;
      color: #a57164;
      padding: 2px 40px 10px 40px;
      ${AddedPoints} {
        padding-top: 3px;
      }

      @media screen and (max-width: 575px) {
        padding: 2px 40px;
        font-size: 18px;
      }
    `}

  @media screen and (max-width: 575px) {
    min-width: 90vw;
  }
`

const RankingTable: React.FC<RankingTableProps> = () => {
  const [rondes, setRondes] = useState<Array<any>>([])
  const [currentRonde, setCurrentRonde] = useState(0)
  const [lastRonde, setLastRonde] = useState<Array<GatsbyTypes.WpParticipant>>(
    []
  )
  const [loading, setLoading] = useState(false)
  const [first, setFirst] = useState(true)
  const [participants, setParticipants] = useState<
    Array<GatsbyTypes.WpParticipant>
  >([])
  const { allWpParticipant } = useStaticQuery<GatsbyTypes.partQuery>(graphql`
    query part {
      allWpParticipant {
        nodes {
          databaseId
          title
          slug
          participantextra {
            displayname
            points
          }
        }
      }
    }
  `)

  const getData = async (keepCurrent = false) => {
    setLoading(true)
    const { data }: any = await API.get({
      url: 'https://gamesadmin.uwbusinessonline.nl/wp-json/wp/v2/pages?page_id=2',
      token: false,
    })

    if (data) {
      const roundData = data[0].acf.flex.filter(
        (f: any) => f.acf_fc_layout === 'ranking'
      )[0].rounds
      setRondes(roundData)

      if (first || keepCurrent) {
        setCurrentRonde(roundData.length - 1)
        setFirst(false)
      }

      // @ts-ignore
      const fakeRoundData = roundData.slice(0, currentRonde + 1)
      const fakeParticipantsData = allWpParticipant.nodes.map(
        (allwpp: any) => ({
          ...allwpp,
          participantextra: { ...allwpp.participantextra },
        })
      )
      const fakeSecondParticipantsData = allWpParticipant.nodes.map(
        (allwpp: any) => ({
          ...allwpp,
          participantextra: { ...allwpp.participantextra },
        })
      )

      setLoading(false)
      setTotalScore(fakeRoundData, fakeParticipantsData)

      if (currentRonde >= 1) {
        setLastScore(
          roundData.slice(0, currentRonde),
          fakeSecondParticipantsData
        )
      }
    }
  }

  useEffect(() => {
    getData()

    setInterval(() => {
      getData(true)
    }, 1000 * 60 * 5)
  }, [])

  const setTotalScore = (rounds: any, particpantList: any) => {
    // @ts-ignore
    const fakeP = particpantList

    rounds.forEach((r: any) => {
      r.participants.forEach((p: any) => {
        fakeP.forEach((fp: any) => {
          if (fp.databaseId === p.participant[0].ID) {
            if (fp.participantextra.points) {
              fp.participantextra.points += parseInt(p.points, 10)
            } else {
              fp.participantextra.points = parseInt(p.points, 10)
            }
          }
        })
      })
    })

    setParticipants(
      fakeP.sort(
        (a: any, b: any) =>
          b.participantextra.points - a.participantextra.points
      )
    )
  }

  const setLastScore = (rounds: any, particpantList: any) => {
    // @ts-ignore
    const fakeP = particpantList

    rounds.forEach((r: any) => {
      r.participants.forEach((p: any) => {
        fakeP.forEach((fp: any) => {
          if (fp.databaseId === p.participant[0].ID) {
            if (fp.participantextra.points) {
              fp.participantextra.points += parseInt(p.points, 10)
            } else {
              fp.participantextra.points = parseInt(p.points, 10)
            }
          }
        })
      })
    })

    setLastRonde(
      fakeP.sort(
        (a: any, b: any) =>
          b.participantextra.points - a.participantextra.points
      )
    )
  }

  useEffect(() => {
    getData()
  }, [currentRonde])

  return (
    <StyledRankingTable>
      <div>
        <div className="d-flex">
          {rondes.map((r: any, index: number) => (
            <RoundButton
              active={currentRonde === index}
              onClick={() => setCurrentRonde(index)}
              key={index}
            >
              {r.roundname}
            </RoundButton>
          ))}
        </div>
        <AnimatePresence>
          <Ranks className="mt-4">
            {!loading ? (
              <motion.div
                initial={{ x: -400 }}
                animate={{ x: 0 }}
                exit={{ x: 400 }}
                key="loaded"
              >
                <InnerRanks>
                  {participants.map((p, pindex) => (
                    <Participant
                      number={pindex}
                      className="d-flex justify-content-between"
                      key={pindex}
                    >
                      {currentRonde > 0 && (
                        <IndexChanged
                          changed={
                            lastRonde.findIndex((f) => f.slug === p.slug) -
                            pindex
                          }
                        >
                          {`${
                            lastRonde.findIndex((f) => f.slug === p.slug) -
                              pindex >
                            0
                              ? `+`
                              : ``
                          }${
                            lastRonde.findIndex((f) => f.slug === p.slug) -
                            pindex
                          }`}
                        </IndexChanged>
                      )}
                      <div className="d-flex">
                        {p.participantextra?.displayname}
                        {currentRonde > 0 && (
                          <AddedPoints
                            changed={
                              lastRonde.findIndex((f) => f.slug === p.slug) -
                              pindex
                            }
                          >
                            {(p.participantextra?.points || 0) -
                              (lastRonde.filter((f) => f.slug === p.slug)[0]
                                ?.participantextra?.points || 0)}
                          </AddedPoints>
                        )}
                      </div>
                      <div>{p.participantextra?.points || 0}</div>
                    </Participant>
                  ))}
                </InnerRanks>
              </motion.div>
            ) : (
              <motion.div
                initial={{ x: -400 }}
                animate={{ x: 0 }}
                exit={{ x: 400 }}
                key="loading"
              >
                <Loading />
              </motion.div>
            )}
          </Ranks>
        </AnimatePresence>
      </div>
    </StyledRankingTable>
  )
}

export default RankingTable
